/* css reset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* actual css starts here */
body{
font-family: Verdana, Geneva, Tahoma, sans-serif;
background-color: black;
color: white;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
		font-size: 16px;
}

.hero-image {
  height: 600px; width: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero{
height: 600px; width: auto; 
background-size: cover;
  position: relative;
}

.hero-text p {
 font-weight: 400;
 letter-spacing: 4px;
 font-size: 1.2em;
}
a {
	color: white;
}

a:hover {
	color: rgb(0, 0, 0);
	text-decoration: none;
}

.nav-item-logo:hover {
	color: white;
	text-decoration: none;
}

.sect {
	padding: 0.5rem 2.5rem;
}

h1 {
 font-size: 2em;
	padding-bottom: 0.3em;
}

h3{
 font-size: 2.5em;
 font-weight: 600;
}

.line {
 width: 30%;
}

hr {
	 background-color: white;
}

.roadmap > hr , .team > hr {
	width: 20%;
}

.feature , ._ , .faq , footer{
 background-color: #232323;
 color: #fff;
}

.feature p , .team p , .cta p{
	font-size: 1.2em;
	text-align: justify;
	padding: 2rem;
}

.team p {
 width: 70%;
 margin: auto;
}

p{
  padding: 0.5em 0;
  line-height: 1.5em;
  font-weight: 600;
}

.collectibles p {
  font-size: 1.3em; 
  line-height: 1.7em;
  font-family: sans-serif;
  text-align: center;
  width: 100%;
  margin-left: 4rem;
  margin-right: 4rem;
}

.team p{
 font-size: 0.8em;
}

#teamname {
 font-size: 1.3em;
 padding: 0;
}

.card {
 background-color: #232323;
}

.card-header {
 padding: 1.2em 0;
}

.question {
 color: white;
 padding: 0;
 font-size: 1.3em;
}

.btn-link:hover , .btn-link:active , .btn-link:focus , .btn-link:visited{
 color: white;
 text-decoration: none;
}

footer p {
	font-weight: 400;
}

@media only screen and (max-width:600px) { 
	 .collectibles, 
	 .feature, 
	 .team, 
	 .roadmap, 
	 .bllc-collectibles-title, 
	 .bllc-story-heading, 
	 .bllc-story-heading-2,
	 .neon-text,
	 .neon-wrapper,
	 .neon-text-title,
	 .bllc-nav-bar,
	 .bllc-nav-links,
	 .bllc-nav-links-container,
	 .timeline, .tl-bg, .tl-year, f2 {
		text-align: center;

	}
	.club-benefits-render-info {
		font-size: 0.3rem !important;
	}
	.club-benefits-text-title, .club-benefits-text-title-2 {
		font-family:neon;
		font-size: 2rem !important;
		color: #fffed8;
	}

	.bllc-clubbenefits__item {
		font-size: 0.3ren;
	}
	.bllc-container {
		background-image: none !important;
	}
	.club-benefits-image {
		image-resolution: inherit;
	}
	.bllc-render-info {
		font-size: 1rem !important;
		line-height: 1rem;
		margin-top: 1rem;
		max-width: 30rem !important;
	}
	.line {
			display: none;
		}
	.bllc-club-benefits-images, .bllc-club-benefits-images-2 {
		display: none;
	}
	.tl-bg {
		transform: translate3d(0, 0, 0);
		width: 100% !important;
		height: 100% !important;
		top: 0;
		left: 0;
		background-position: center center;
		transition: filter 0.5s ease;
		filter: grayscale(100%);
	}
	
	.tl-desc {
		font-size: .7ren !important;
	}
	.tl-title {
		font-size: 1.3em !important;
	}
	.tl-item {
		transform: translate3d(0, 0, 0);
		position: relative;
		width: 50%;
		height: 30vh;
		min-height: 500px;
		color: #fff;
		overflow: hidden;
		transition: width 0.5s ease;
	  }
	  .tl-item:before, .tl-item:after {
		transform: translate3d(0, 0, 0);
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	  }
	  .tl-item:after {
		background: rgba(3, 22, 37, 0.85);
		opacity: 1;
		transition: opacity 0.5s ease;
	  }
	  .tl-item:before {
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 75%);
		z-index: 1;
		opacity: 0;
		transform: translate3d(0, 0, 0) translateY(50%);
		transition: opacity 0.5s ease, transform 0.5s ease;
	  }
	  .tl-item:hover:after {
		opacity: 0;
	  }
	  .tl-item:hover:before {
		opacity: 1;
		transform: translate3d(0, 0, 0) translateY(0);
		transition: opacity 1s ease, transform 1s ease 0.25s;
	  }
	  .tl-item:hover .tl-content {
		opacity: 1;
		transform: translateY(0);
		transition: all 0.75s ease 0.5s;
	  }
	.tl-item:hover .tl-bg {
		filter: grayscale(0);
	}
	.bllc-clubbenefits {
		display: grid;
		grid-template-columns: 1fr !important;
	}
	.roadmap-content{
		font-size: 0.75em;
	}
}


.bllc-container {
	background-image: url("../../assets/images/websitebanner.png");
	background-position-y: -3.5rem;
    background-repeat: no-repeat;
    background-position-x: 17rem;
    background-size: 86%;
}

.logo {
	width: 151px;
}

.bllc-nav-links {
	align-items: center;
}

.bllc-nav-links .nav-item {
	margin-left: 1rem;	

}

.bllc-nav-links .nav-item a {
	font-size: 1rem;
}

.bllc-nav-bar {
	display: flex;
	justify-content: space-between;
}

.bllc-nav-links-container {
	justify-content: end;
}

.club-benefits-section {
	cursor: pointer;
}

.buttonContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
  }
  
.disclaimerText {
font-size: 10px;
}

.bllc-dropdown {
	margin-left: 2rem;
}

.bllc-clubbenefits {
	display: grid;
	grid-template-columns: repeat(2,1fr);
	gap: 1rem;
	justify-content: center;
}

.bllc-clubbenefits__item {
	display:flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-size: 1.1em;
}

.roadMap__item {
	display: flex;
	justify-content: center;
	align-items: center;
	float: left;
	width: 50%;
	padding: 10px;
	height: 300px;
	flex-direction: column;
	font-size: 1.4em;
}

.bllc-collectibles-title {
	text-align: center;
    font-size: 4rem;
    text-transform: uppercase;
    font-weight: bold;
	margin-top: 4rem;
}
  
.bllc-render-info {
	line-height: 1.5rem;
	margin-top: 1rem;
	max-width: 30rem;
}

.bllc-story-heading {
	padding-top: 1rem;
	text-align:center;
	letter-spacing: 0.5rem;
	text-transform:uppercase;
	-webkit-text-stroke: 0.2px white;
	-webkit-text-stroke-width: 0.1rem;
	-webkit-text-fill-color: rgb(0, 0, 0);
	background: transparent;
	font-size: 3rem !important;
}

.bllc-story-heading-2 {
	margin-top: -1rem;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 0.5rem;
	-webkit-text-stroke:0.2px white;
	-webkit-text-stroke-width: 0.2rem;
	-webkit-text-fill-color: black;
	font-size: 5rem !important;
}

.roadMap--line{
    width: 10px;
	height: 100%;
	border: 1px solid #e41a51;
}

.roadmap--line-empty {
    height: 86%;
    background: linear-gradient(#e6ca0f,#e41a51)!important;
}

.roadMap-timeline {
	display: grid;
	grid-template-columns: 20rem 10rem auto;
}

.item-title {
    text-align: center;
    color: #fff;
    font-size: 22px;
    font-family: brandontext!important;
    margin-bottom: 0!important;
    padding-top: 1rem;
}

.roadmap--point {
    position: absolute;
    left: 14px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    display: block;
    width: 12px;
    height: 12px;
    background: #fff;
    border-radius: 100%;
    flex-shrink: 0;
    margin-top: 95px;
    box-shadow: 0 0 9px 9px rgb(0 0 0 / 55%);
}

@font-face {
	font-family: neon;
	src: url(../../assets/fonts/hello-denver-display.denver-display-regular-regular.ttf);
}

.neon-text-title {
	font-family:neon;
	font-size: 3.3rem;
	color: #ffd8d8;
	padding-bottom: 2remç;
  }
  .neon-text {
	font-family:neon;
	font-size: 2.3rem;
	color: #ffd8d8;
  }

  .neon-text {
	text-shadow: 
	.0vw 0vw .0vw #ffd8d8, .1vw 0vw .1vw #ffd8d8, .0vw 0vw .0vw #ffd8d8, 
	.1vw 0vw   0vw #f25757, .2vw 0vw   0vw #f25757, .1vw 0vw   0vw #f25757,
	.1vw 0vw  .1vw #f25757, .2vw 0vw  .1vw #f25757, .1vw 0vw  .1vw #f25757,
	.1vw 0vw   1vw #e50b0b, .2vw 0vw  2w #e50b0b, .1vw 0vw  1vw #e50b0b,
	.1vw 0vw  2vw #e50b0b, .1vw 0vw  5vw #e50b0b, .1vw 0vw  2vw #e50b0b;
  }

  .neon-text-title {
	text-shadow: 
	.0vw 0vw .0vw #ffd8d8, .1vw 0vw .1vw #ffd8d8, .0vw 0vw .0vw #ffd8d8, 
	.1vw 0vw   0vw #f25757, .2vw 0vw   0vw #f25757, .1vw 0vw   0vw #f25757,
	.1vw 0vw  .1vw #f25757, .2vw 0vw  .1vw #f25757, .1vw 0vw  .1vw #f25757,
	.0vw 0vw   1vw #e50b0b, .1vw 0vw  2vw #e50b0b, .0vw 0vw  1vw #e50b0b,
	.1vw 0vw  2vw #e50b0b, .1vw 0vw  5vw #e50b0b, .1vw 0vw  2vw #e50b0b;
  }

  .wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
  }

  .club-benefits-text-title {
	font-family:neon;
	font-size: 4rem;
	color: #fffed8;
  }

  /* .club-benefits-text-title {
	text-shadow: 
	.0vw 0vw .0vw #ffd8d8, .1vw 0vw .1vw #ffd8d8, .0vw 0vw .0vw #ffd8d8, 
	 .1vw 0vw   0vw #f2ce57, .2vw 0vw   0vw #f2ce57, .1vw 0vw   0vw #f2ce57,
	 .1vw 0vw  .1vw #f2ce57, .2vw 0vw  .1vw #f2ce57, .1vw 0vw  .1vw #f2ce57,
	.0vw 0vw   1vw #cec109d3, .1vw 0vw  2vw #cec109d3, .0vw 0vw  1vw #cec109d3,
	.1vw 0vw  2vw #cec109d3, .1vw 0vw  5vw #cec109d3, .1vw 0vw  2vw #cec109d3;
  } */

  .club-benefits-text-title {
	text-shadow: 
	.0vw 0vw .0vw #c3fcc8, .1vw 0vw .1vw #c3fcc8, .0vw 0vw .0vw #c3fcc8, 
	 .1vw 0vw   0vw #5ce74a, .2vw 0vw   0vw #5ce74a, .1vw 0vw   0vw #5ce74a,
	 .1vw 0vw  .1vw #5ce74a, .2vw 0vw  .1vw #5ce74a, .1vw 0vw  .1vw #5ce74a,
	.0vw 0vw   1vw #5cc705, .1vw 0vw  2vw #5cc705, .0vw 0vw  1vw #5cc705,
	.1vw 0vw  2vw #5cc705, .1vw 0vw  5vw #5cc705, .1vw 0vw  2vw #5cc705;
  }

  .club-benefits-text-title-2 {
	font-family:neon;
	font-size: 4rem;
	color: #fffed8;
  }

  .club-benefits-text-title-2 {
	text-shadow: 
	.0vw 0vw .0vw #ffb9e2, .1vw 0vw .1vw #ffb9e2, .0vw 0vw .0vw #ffb9e2, 
	 .1vw 0vw   0vw #da4dd3, .2vw 0vw   0vw #da4dd3, .1vw 0vw   0vw #da4dd3,
	 .1vw 0vw  .1vw #da4dd3, .2vw 0vw  .1vw #da4dd3, .1vw 0vw  .1vw #da4dd3,
	.0vw 0vw   1vw #a907c9, .1vw 0vw  2vw #a907c9, .0vw 0vw  1vw #a907c9,
	.1vw 0vw  2vw #a907c9, .1vw 0vw  5vw #a907c9, .1vw 0vw  2vw #a907c9;
  }

  body {
	font-family: "Source Sans Pro", Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 1.75;
  }
  
  #timeline {
	display: flex;
	background-color: #031625;
  }
  #timeline:hover .tl-item {
	width: 15.3333%;
  }
  
  .tl-item {
	transform: translate3d(0, 0, 0);
	position: relative;
	width: 50%;
	height: 30vh;
	min-height: 500px;
	color: #fff;
	overflow: hidden;
	transition: width 0.5s ease;
  }
  .tl-item:before, .tl-item:after {
	transform: translate3d(0, 0, 0);
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
  }
  .tl-item:after {
	background: rgba(3, 22, 37, 0.85);
	opacity: 1;
	transition: opacity 0.5s ease;
  }
  .tl-item:before {
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 75%);
	z-index: 1;
	opacity: 0;
	transform: translate3d(0, 0, 0) translateY(50%);
	transition: opacity 0.5s ease, transform 0.5s ease;
  }
  .tl-item:hover {
	width: 100% !important;
  }
  .tl-item:hover:after {
	opacity: 0;
  }
  .tl-item:hover:before {
	opacity: 1;
	transform: translate3d(0, 0, 0) translateY(0);
	transition: opacity 1s ease, transform 1s ease 0.25s;
  }
  .tl-item:hover .tl-content {
	opacity: 1;
	transform: translateY(0);
	transition: all 0.75s ease 0.5s;
  }
  .tl-item:hover .tl-bg {
	filter: grayscale(0);
  }
  
  .tl-content {
	transform: translate3d(0, 0, 0) translateY(25px);
	position: absolute;
	z-index: 1;
	text-align: justify;
	margin: 0 1.618em;
	top: 40%;
	opacity: 0;
  }
  .tl-content h1 {
	font-family: "Pathway Gothic One", Helvetica Neue, Helvetica, Arial, sans-serif;
	text-transform: uppercase;
	color: #ffffff;
	font-size: 1.44rem;
	font-weight: normal;
	align-content: flex-start;
  }
  
  .tl-year {
	position: absolute;
	top: 20%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	z-index: 1;
	border-top: 1px solid #fff;
	border-bottom: 1px solid #fff;
  }
  .tl-year p {
	font-family: "Pathway Gothic One", Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 1.728rem;
	line-height: 0;
  }
  
  .tl-bg {
	transform: translate3d(0, 0, 0);
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-size: cover;
	background-position: center center;
	transition: filter 0.5s ease;
	filter: grayscale(100%);
  }