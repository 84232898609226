.selector-for-some-widget {
    box-sizing: content-box;
}

:root {
    --aeto-color:  #ffc007;
  }

body {
    background-color: #000;
    color: white;
    font-family: "Montserrat", sans-serif;
}

/* home page */
.welcome {
    margin-top: -287px;
    background: rgba(0, 0, 0, 0.8);
    padding: 20px 0px 25px;
    text-align: center;
}
.welcome-title {
    font-weight: 900;
    font-size: 2.2em;
    font-style: italic;
    letter-spacing: 0.1rem;
}
.banner {
    margin-top: -40px;
    max-width: 600px;
    background-color: #000;
}
.aeto-button {
    background-color: var(--aeto-color);
    border: 2px solid black;
    color: #000;
    font-weight: 600;
    font-size: 20px;
    transition: 0.3s;
    padding: 10px 30px;
}
.custom-logo {
    position: relative;
    margin-bottom: -37px;
    z-index: 1;
}
.home-button {
    padding: 10px 50px;
    font-weight: 600;
}
.social-icons {
    padding-top: 30px;
}
/* home page */
.navbar-social {
    padding-left: 40px;
    padding-top: 2px;
}
#nav {
    background-color: black;
}
.nav-link,
.dropdown-item {
    font-size: 0.8rem;
}
.social-link {
    color: white;
    letter-spacing: 10px;
}
.common-p {
    text-align: justify;
    font-size: 1em;
    line-height: 1.5rem;
}
.fair-title {
    color: #ffc007;
    font-weight: 400;
}
.note {
    font-size: 0.8rem;
    font-weight: 200;
    font-style: italic;
}
.common-title {
    font-weight: 800;
    font-style: italic;
    font-size: 1.7rem;
}

.common-container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}
.buy-token-container {
    background-color: #ffc007;
    color: black;
}
.opensea-cta {
    background-color: #000;
    color: #ffc007;
    border-radius: 8px;
    border: 2px solid #000;
    font-weight: 600;
    font-size: 16px;
    padding: 5%;
    width: 100%;
    margin: auto;
    transition: 0.3s;
}
.opensea-cta:hover {
    border: 2px solid black;
    background-color: #ffc007;
    color: #000;
    cursor: pointer;
    text-decoration: none;
    transition: color 0.3s, border-color 0.3s, transform 0.5s;
}

.bold-text {
    font-weight: 600;
}
.mint {
    font-size: 1.5rem !important;
    font-weight: 600;
}
.aeto-color {
    color: #ffc007;
}
.link {
    color: #ffc007;
    text-decoration: underline;
    word-wrap: break-word;
}
.link:hover {
    color: #fff;
}
.goal {
    color: #ffc007;
    font-weight: 500;
    font-style: italic;
    font-size: 1em;
}
.common-title {
    font-weight: 800;
    font-style: italic;
}
.common-sub {
    font-size: 0.8rem;
    padding-top: 15px;
}

.gif {
    max-width: 17rem;
    width: auto;
    text-align: center;
}

.founders {
    max-width: 50%;
}

footer {
    padding: 40px 0px;
}
footer a {
    padding: 5px;
}
footer a:last-child {
    padding: 0 0 0 5px;
}
.social-footer {
    padding-top: 20px;
    font-size: 22px;
    text-align: right;
}
.copyright {
    font-size: 0.8rem;
    letter-spacing: 0.1rem;
}
hr {
    border-bottom: 3px solid #3b3b3b;
    width: 80%;
}
.gray-line {
    width: 100%;
}
.modal-header,
.modal-footer {
    border-bottom: 0 none;
    border-top: 0 none;
    padding: 0;
}
.modal-body {
    padding-bottom: 0;
}
.model-gif {
    max-width: 80px;
    width: 100%;
}
.modal-title {
    color: black;
    font-weight: 600;
    font-size: 2rem;
}

.p1 {
    font-size: 26px;
    font-weight: 600;
    padding: 10px 0 0;
    margin-bottom: 0;
}

.modal-p {
    line-height: 1.5rem;
}
.modal-cta,
.modal-cta2 {
    padding: 8px 36px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    cursor: pointer;
}
.modal-note {
    font-size: 0.6rem;
}
.modal-footer button {
    margin: 0 8px 8px 0;
}

.modal-cta2 {
    min-width: 70%;
}

@media only screen and (max-width: 600px) {
    .welcome {
        margin-top: -30px;
    }
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 992px) {
    .welcome {
        background-color: #ffc007;
        color: black;
        box-shadow: 0px 0px 8px #ffc007;
    }
    .aeto-button {
        background-color: #000;
        color: #ffc007;
        font-weight: 600;
        font-size: 20px;
        transition: 0.3s;
    }
    .aeto-button:hover {
        border: 2px solid black;
        background-color: #ffc007;
        cursor: pointer;
        transition: color 0.3s, border-color 0.3s, transform 0.5s;
    }
}
